import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { forkJoin } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { PricingEquationService } from 'src/app/services/pricing-equation.service';
import { CustomerPriceDefault } from './customer-price-default';

@Component({
  selector: 'app-customer-price-defaults',
  templateUrl: './customer-price-defaults.component.html',
  styleUrls: ['./customer-price-defaults.component.scss'],
})
export class CustomerPriceDefaultsComponent implements OnInit, AfterViewInit {
  constructor(
    private customerService: CustomerService,
    private pricingEquationService: PricingEquationService,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'companyName',
    'stdLaborPerMinCost',
    'settingLaborPerMinCost',
    'ohPercent',
    'profitPercent',
    'emptyCell',
  ];

  customers = new MatTableDataSource<CustomerPriceDefault>([]);
  defaultForCustomer: CustomerPriceDefault;
  isSaving = false;

  ngOnInit(): void {
    forkJoin([
      this.customerService.getList(),
      this.pricingEquationService.getCustomerDefaults(),
    ]).subscribe(([customers, customerDefaults]) => {
      this.defaultForCustomer = customerDefaults.find(
        (d) => d.customerID === 0,
      );
      this.customers.data = orderBy(customers, ['companyName']).reduce(
        (accum, { custIdno: customerID, companyName }, index) => {
          const customerDefault = customerDefaults.find(
            (d) => d.customerID === customerID,
          );
          return [
            ...accum,
            {
              customerID,
              companyName,
              sortOrder: index + 1,
              ...(customerDefault ?? {}),
            },
          ];
        },
        [
          {
            customerID: 0,
            companyName: 'All Customers',
            sortOrder: 0,
            ...(this.defaultForCustomer ?? {}),
          },
        ],
      );
    });
  }

  ngAfterViewInit(): void {
    this.customers.sort = this.sort;
  }

  onSaveDefaults() {
    const requests = this.customers.data.reduce((accum, customer) => {
      const hasValue =
        (customer.stdLaborPerMinCost ?? 0) !== 0 ||
        (customer.settingLaborPerMinCost ?? 0) !== 0 ||
        (customer.ohPercent ?? 0) !== 0 ||
        (customer.profitPercent ?? 0) !== 0;
      if (customer.prC_DefaultsID > 0 || hasValue) {
        return [
          ...accum,
          this.pricingEquationService.setCustomerDefaults({
            ...customer,
            stdLaborPerMinCost: customer.stdLaborPerMinCost || null,
            settingLaborPerMinCost: customer.settingLaborPerMinCost || null,
            ohPercent: customer.ohPercent || null,
            profitPercent: customer.profitPercent || null,
          }),
        ];
      }
      return accum;
    }, []);
    this.isSaving = true;
    forkJoin(requests).subscribe(() => {
      this.defaultForCustomer = {
        ...(this.customers.data.find((c) => c.customerID === 0) ??
          ({} as CustomerPriceDefault)),
      };
      this.isSaving = false;
    });
  }
}
