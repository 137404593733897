import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerPriceDefault } from '../components/price-defaults/customer-price-defaults/customer-price-default';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class PricingEquationService extends GenericService {
  baseUrl = `${environment.apiUrl}pricing-equation`;

  constructor(http: HttpClient) {
    super(http);
  }

  getCustomerDefaults() {
    return this.http
      .get<CustomerPriceDefault[]>(`${this.baseUrl}/customer-defaults`, {
        headers: this.headers,
      })
      .pipe(
        map((data) =>
          data.map(
            ({
              stdLaborPerMinCost,
              settingLaborPerMinCost,
              ohPercent,
              profitPercent,
              ...priceDefaults
            }) => ({
              ...priceDefaults,
              stdLaborPerMinCost: stdLaborPerMinCost || null,
              settingLaborPerMinCost: settingLaborPerMinCost || null,
              ohPercent: ohPercent || null,
              profitPercent: profitPercent || null,
            }),
          ),
        ),
      );
  }
  setCustomerDefaults(data: CustomerPriceDefault) {
    return this.http.post(`${this.baseUrl}/customer-defaults`, data, {
      headers: this.headers,
    });
  }
}
